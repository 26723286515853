import { calculateEditionProps, CalulatedEditionProps } from "./editions";
import { MetadataJSONSchema } from "~/api/metadata";
import type { Token } from "~/api/data-schema";

export function getEditionProps(
    tokenId: number,
    metadata: MetadataJSONSchema | undefined,
    editions: Token[],
): CalulatedEditionProps {
    const ap = Number(metadata?.properties?.AP?.description) || 0;
    const editionIndex = editions.findIndex((el) => el.tokenId === tokenId);
    const editionsLength = editions?.length || 0;

    return calculateEditionProps({ ap, editionsLength, editionIndex });
}
