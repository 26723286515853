
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/drop/artuner",
      function () {
        return require("private-next-pages/drop/artuner.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/drop/artuner"])
      });
    }
  