export type PhygitalData = {
    phygitalMedium?: string;
    phygitalType?: string;
    phygitalDimensions?: {
        width: number;
        height: number;
    };
    phygitalImage?: string;
};

export type MetadataProperty<T = string | number | boolean> = {
    type: string;
    description: T;
};

export type MetadataProperties = {
    name: MetadataProperty<string>;
    description: MetadataProperty;
    image?: MetadataProperty;
    media?: MetadataProperty;
    createdAt: MetadataProperty;
    fileName: MetadataProperty;
    fileSize: MetadataProperty;
    fileType?: MetadataProperty;
    preview?: MetadataProperty;
    hasAudio?: MetadataProperty;
    previewFileType?: MetadataProperty;
    AP?: MetadataProperty;
    artistSign?: MetadataProperty;
    tokenBundle?: MetadataProperty;
    artistName?: MetadataProperty<string>;
    artistBirthDate?: MetadataProperty;
    artworkCreationYear?: MetadataProperty;
    saleConditions?: MetadataProperty;
    embeddedPreview?: MetadataProperty;
    application?: MetadataProperty;
    tokenType?: MetadataProperty;
    vimeoId?: MetadataProperty<number>;
    artworkFactSheet?: MetadataProperty<string>;
    ownershipGuidelines?: MetadataProperty<string>;
    [key: string]: MetadataProperty | undefined;
};

export type MetadataJSONSchema = {
    title: string | number;
    type: "object";
    properties: MetadataProperties;
    image: string;
    description: string;
    name: string;
    external_url?: string;
    phygitalData: PhygitalData[];
};

export const basicProperties = [
    "name",
    "description",
    "image",
    "media",
    "createdAt",
    "fileName",
    "fileSize",
    "fileType",
    "preview",
    "previewFileType",
    "AP",
    "artistSign",
    "tokenBundle",
    "artistName",
    "artistBirthDate",
    "artworkCreationYear",
    "saleConditions",
    "hasAudio",
    "embeddedPreview",
    "application",
    "tokenType",
    "vimeoId",
    "artworkFactSheet",
    "ownershipGuidelines",
    "selectedContract",
];
