import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import dynamic from "next/dynamic";
import { Token } from "~/api/data-schema";

const About = dynamic(() => import("./About").then((module) => module.About));
const Banner = dynamic(() =>
    import("./Banner").then((module) => module.Banner),
);
const Biography = dynamic(() =>
    import("./Biography").then((module) => module.Biography),
);
const Cards = dynamic(() => import("./Cards").then((module) => module.Cards));
const Installation = dynamic(() =>
    import("./Installation").then((module) => module.Installation),
);
const Process = dynamic(() =>
    import("./Process").then((module) => module.Process),
);
const Roadmap = dynamic(() =>
    import("./Roadmap").then((module) => module.Roadmap),
);

interface Properties extends HTMLAttributes<HTMLElement> {
    tokens: Token[];
}

export const Page: FunctionComponent<Properties> = ({
    children,
    className,
    tokens,
    ...rest
}: Properties): ReactElement => {
    return (
        <div className={className} {...rest}>
            <Banner data-testid="artruner-banner" />
            <Biography data-testid="artruner-biography" />
            <Cards data-testid="artruner-cards" tokens={tokens} />
            <Process data-testid="artruner-process" />
            <Installation data-testid="artruner-installation" />
            <About data-testid="artruner-about" />
            <Roadmap data-testid="artruner-roadmap" />
        </div>
    );
};
