import { useSelector } from "react-redux";
import { basicProperties, MetadataProperty } from "~/api/metadata";
import type { Token } from "~/api/data-schema";
import { artworkStateSelector } from "./artworkReducer";

export function useTokenData(tokenId: number) {
    const { tokens, contractAddress } = useSelector(artworkStateSelector);

    const token = tokens.reduce((acc, el) => {
        if (el.tokenId === tokenId) {
            return el;
        }
        const edition = el.editions.find(
            (editionEl) => editionEl.tokenId === tokenId,
        );
        if (edition) {
            return {
                ...edition,
                editions: el.editions,
                metadata: el.metadata,
            };
        }

        return acc;
    }, undefined) as Token;
    const editions = [...(token?.editions || [])].sort(
        (token1, token2) => token1.tokenId - token2.tokenId,
    );
    const metadata = token?.metadata;
    const editionNumber =
        editions.findIndex((el) => el.tokenId === tokenId) + 1;

    const properties: {
        key: string;
        value: MetadataProperty["description"];
    }[] = Object.entries(metadata?.properties || {})
        .filter(([key]) => !basicProperties.includes(key))
        .map(([key, property]: [string, MetadataProperty]) => ({
            key,
            value: String(property?.description),
        }));

    return {
        token,
        metadata,
        editions,
        editionNumber,
        contractAddress,
        properties,
    };
}
