import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import type { Policy } from "~/api/data-schema";
import { artworkStateSelector } from "./artworkReducer";

export function useTokenRights(tokenId: number): {
    [wallet: string]: Policy[];
} {
    const { tokenRights } = useSelector(artworkStateSelector);

    const rights = tokenRights[tokenId] || [];

    return groupBy(rights, (el: Policy) => el.permission.wallet);
}
