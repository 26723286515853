import { useMemo } from "react";
import { MetadataJSONSchema } from "~/api/metadata";

export function useArtistName(metadata?: MetadataJSONSchema) {
    const artistName = useMemo(
        () => metadata?.properties?.artistName?.description,
        [metadata],
    );
    return artistName as string;
}
