import { useMemo } from "react";
import { TokenType } from "~/api/data-schema";

export function useTokenType(tokenType: TokenType) {
    return useMemo(() => {
        switch (tokenType) {
            case TokenType.ERC721_WITH_BUY_OUT:
                return "Extended";
            case TokenType.ERC721_WITH_VOLUNTARY_ROYALTIES:
                return "Standard";
            case TokenType.PLAIN_ERC721:
                return "Basic";
            case TokenType.PROPRIETARY_DEPRECATED:
                return "LiveArt V1";
            default:
                return tokenType;
        }
    }, [tokenType]);
}
