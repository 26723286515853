export interface CalulatedEditionProps {
    hasAp: boolean;
    isAp: boolean;
    editionStr: string;
    editionFullStr: string;
}

export function calculateEditionProps({
    ap,
    editionsLength,
    editionIndex,
}: {
    ap: number;
    editionsLength: number;
    editionIndex: number;
}): CalulatedEditionProps {
    const hasAp = !!ap;

    const isAp = editionIndex >= editionsLength - ap;

    const editionStr = isAp
        ? `${editionIndex + 1 - (editionsLength - ap)}/${ap}`
        : `${editionIndex + 1}/${editionsLength - ap}`;

    const editionFullStr = `Edition ${editionIndex + 1} of ${
        editionsLength - ap
    }${ap > 0 ? ` + ${ap} AP` : ""}`;

    return {
        hasAp,
        isAp,
        editionStr,
        editionFullStr,
    };
}
