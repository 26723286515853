import dynamic from "next/dynamic";

export * from "./artworkReducer";
export * from "./useTokensList";
export * from "./useTokensLoaded";
export * from "./useTokenData";
export * from "./useTokenRights";
export * from "./useTokenId";

export const ArtworkCard = dynamic(() =>
    import("./card").then((module) => module.ArtworkCard),
);
export const ArtworkSlide = dynamic(() =>
    import("./slide").then((module) => module.ArtworkSlide),
);
export * from "./useArtistName";
export * from "./useArtworkName";
export { getEditionProps } from "./getEditionProps";
export * from "./media";
export const ArtworkSellPreview = dynamic(() =>
    import("./sellPreview").then((module) => module.ArtworkSellPreview),
);
export * from "./artworkSaga";
export * from "./useTokenType";
export * from "./useContractAddress";
