import { useEffect } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { MAIN_NET_ID } from "@liveart/nft-client/dist/chainId";
import { useDispatch } from "react-redux";
import { loadTokens } from "~/api/choreography";
import { Page } from "~/features/artuner";
import { Token } from "~/api/data-schema";
import { useTokensList } from "~/features/artwork";
import { getTokensByApplicationsFromFirebase } from "~/BFF/BE";
import { GTM } from "~/features/GTM";

const tokenIds: string[] = [
    // May 12, 2022
    "99",
    "100",
    "121",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    // May 22, 2022
    // "111",
    // "112",
    // "113",
    // "114",
    // "115",
    // "116",
    // "117",
    // "118",
    // "119",
    // "120",
    // "122",
    // "123",
];

export async function getStaticProps() {
    const tokens = await getTokensByApplicationsFromFirebase({
        applicationArray: [SupportedApplication.liveart],
        networkId: String(MAIN_NET_ID),
        limit: -1,
        offset: -1,
        tokenIds: tokenIds.map((el) => parseInt(el, 10)),
        editionOf: -1,
    });

    return {
        props: {
            tokens,
        },
        revalidate: 10,
    };
}

export default function ArtunerDropPage({
    tokens: serverTokens = [],
}: {
    tokens: Token[];
}): JSX.Element {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            loadTokens({
                tokenIds,
                limit: -1,
                offset: -1,
                applications: [SupportedApplication.liveart],
                networkId: MAIN_NET_ID,
            }),
        );
    }, [dispatch]);

    const clientTokens: Token[] = useTokensList();

    const tokens = clientTokens?.length ? clientTokens : serverTokens;

    return (
        <>
            <GTM trackingId="G-DMQ82PBH42" />
            <Page tokens={tokens} />
        </>
    );
}
