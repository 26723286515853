import React from "react";
import Head from "next/head";

interface Props {
    trackingId: string;
}

export function GTM({ trackingId }: Props) {
    return (
        <Head>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', '${trackingId}');
                    `,
                }}
            />
        </Head>
    );
}
