import format from "date-fns/format";
import { MetadataJSONSchema } from "~/api/metadata";

export function useArtworkName(metadata: MetadataJSONSchema) {
    let date: string | undefined | null = null;

    try {
        date =
            (metadata.properties.artworkCreationYear?.description as string) ||
            format(
                new Date(metadata.properties.createdAt.description as string),
                "yyyy",
            );
    } catch (error) {
        //
    }

    return [metadata?.properties?.name?.description, date]
        .filter((el) => el)
        .join(", ");
}
